import React from 'react';
import logo from '../logo3dfooterwhite.svg';
const Footer = () => {
const currentYear = new Date().getFullYear(); // Obtiene el año actual

return (
  <footer className='footer'>
    <div className='rightColumn'>
      <div className='leftColumn'>
        <img src={logo} alt="Scala" className="header-logo" />
        <p>Scala © {currentYear}. Todos los derechos reservados.</p>
      </div>
      <div className='rightColumn'>
      <div className='footer-menu'>
        <div className='footer-column'>
          <h3>Legal</h3>
          <ul>
            <li><a href="/terms">Términos y condiciones</a></li>
            <li><a href="/privacy">Política de privacidad</a></li>
          </ul>
        </div>
        <div className='footer-column'>
          <h3>Manifiesto</h3>
          <ul>
            <li><a href="/mission">Misión</a></li>
          </ul>
        </div>
        <div className='footer-column'>
          <h3>Contacto</h3>
          <ul>
            <li><a href='mailto:info@scala-app.com'>Correo electrónico</a></li>
            <li><a href='/contact'>Página de contacto</a></li>
          </ul>
        </div>
      </div>      </div>      </div>
    </footer>
  );
};

export default Footer;
